<template>

    <div class="page-content">
        <div class="container-fluid">

            <Breadcrumb
                :pgTitle="model_type"
                :pgIcon="'bx bx-user'"
                :refs="refs"
                :addNew="false">
            </Breadcrumb>


            <MinDataTable
                :refs="refs"
                :user_id="this.$route.params.user_id"
                :model_type="this.$route.params.model_type"
                :colspan=9

                :hasSearchFilter=false
                :hasDateFilter=false

                :hasHashId=true
                :hasMultiSelect=false
                :hasBulkAction=true
                :HasExport=true
                :hasShowEntries=true
                :hasSearch=true

                :lblSearch="$t('app.search_by_name')"

                :hasUser=true
                :image="$t('view.user')"
                :image_style="'width: 40px;height: 40px;border-radius: 50%'"

                :hasCourseName=true
                :course_name="$t('view.course_title')"

                :hasPrice=true
                :price="$t('view.course_price')"

                :hasDate=true
                :hasPaid=true
                :hasDeleteCourse=true
                :hasActions=true

                :actionable=true
                :sortable=true>
            </MinDataTable>

        </div>
    </div>

</template>

<script>
export default {
    name: 'List',
    components: {
        Breadcrumb: () => import('@/components/Breadcrumb.vue'),
        MinDataTable: () => import('@/components/MinDataTable.vue')
    },
    data(){
        return {    
            //
            model_type: '',
            addNew: false,
            refs: 'users',
        }
    },
    watch: {
        //
    },
    mounted() {},
    created() {
        //
        if(this.$route.params.model_type) {
            this.model_type = this.$t('nav.'+this.$route.params.model_type);
        }
    },
    methods: {
        //
    },
}
</script>
